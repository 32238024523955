import store from '@admin/store'
import AdminLayout from '@admin/layouts/AdminLayout'
import { adminLegacyUrl } from "@shared/helpers/env-helpers"

export default {
  path: '/admin',
  component: AdminLayout,
  redirect: { name: 'Welcome' },

  children: [
    {
      path: '/welcome',
      name: 'Welcome',
      component: () => import('@admin/pages/admin/Welcome'),
    },
    {
      path: '/brands',
      name: 'Brands',
      component: () => import('@admin/pages/admin/Brands'),
    },
    {
      path: '/lm_table',
      name: 'LmTable',
      component: () => import('@admin/pages/admin/LocalMonitor/LmTable'),
    },
    {
      path: '/lm_graph',
      name: 'LmGraph',
      component: () => import('@admin/pages/admin/LocalMonitor/LmGraph'),
    },
    {
      path: '/local_insights/google_places',
      name: 'GooglePlaces',
      component: () => import('@admin/pages/admin/LocalInsight/GooglePlaces'),
    },
    {
      path: '/local_insights/lm_brands',
      name: 'LmBrands',
      component: () => import('@admin/pages/admin/LocalInsight/LmBrands'),
    },
    {
      path: '/local_insights/lm_brand_factories',
      name: 'LmBrandFactories',
      component: () => import('@admin/pages/admin/LocalInsight/LmBrandFactories'),
    },
    {
      path: '/local_insights/lm_brand_factories/:id',
      name: 'LmBrandFactory',
      component: () => import('@admin/pages/admin/LocalInsight/LmBrandFactory'),
      props: (route) => ({
        lmBrandFactoryId: route.params.id
      }),
    },
    {
      path: '/local_insights/lm_brand_factories/:id/localized_lm_brands/:lmBrandId',
      name: 'LocalizedLmBrand',
      component: () => import('@admin/pages/admin/LocalInsight/LmBrandFactory/LocalizedLmBrand'),
      props: (route) => ({
        lmBrandFactoryId: route.params.id,
        lmBrandId: route.params.lmBrandId
      }),
    },
    {
      path: '/local_insights/verticals',
      name: 'Verticals',
      component: () => import('@admin/pages/admin/LocalInsight/Verticals'),
    },
    {
      path: "/billing",
      name: "Billing",
      component: () => import("@admin/pages/admin/Billing")
    },
    {
      path: "/net_impact_score",
      name: "NetImpactScore",
      component: () => import("@admin/pages/admin/NetImpactScore")
    },
    {
      path: '/local_insights/lm_brands/:id',
      name: 'LmBrand',
      component: () => import('@admin/pages/admin/LocalInsight/LmBrand'),
    },
    {
      path: "/",
      name: "AdminLegacy",
      beforeEnter(to, from, next) {
        window.open(`${adminLegacyUrl}?t=${localStorage.wizvilleSessionToken}`)
      }
    },
    {
      path: "/brands_export",
      name: "BrandsExport",
      component: () => import('@admin/pages/admin/BrandsExport'),
    },
    {
      path: "/monitoring/local_insight_reporting",
      name: "MonitoringLocalInsightReporting",
      component: () => import('@admin/pages/admin/Monitoring/LocalInsightReporting')
    },
    {
      path: '/admin_constants',
      name: 'AdminConstants',
      component: () => import('@admin/pages/admin/AdminConstants')
    },
    {
      path: '/pub_sub_messages',
      name: 'PubSubMessages',
      component: () => import('@admin/pages/admin/PubSubMessages')
    },
    {
      path: '/tracking_events',
      name: 'TrackingEvents',
      component: () => import('@admin/pages/admin/TrackingEvents')
    }
  ]
}
